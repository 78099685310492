.alert-hint {
  font-size: 85%;
  padding: 8px 12px;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
  hr {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .markdown-panel {
    padding: 0;
    :last-child {
      margin: 0;
    }
  }
}
