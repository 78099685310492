.alert-help {
  color: #888;
  background-color: #eee;
  border-color: #ccc;
  font-size: 85%;
  padding: 8px 12px;
  margin-bottom: 8px;
  opacity: 0.33;
  &:hover {
    opacity: 1;
  }
  &>i.fa {
    margin-right: 8px;
    margin-bottom: 8px;
    font-size: 160%
  }
  .markdown-panel {
    padding: 0;
    :last-child {
      margin: 0;
    }
  }
}
