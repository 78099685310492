.form-label-help , .markdown-panel , .qtip-default.qtip-gmcd .qtip-content {
  :last-child {
    margin: 0;
  }
  ul , ol {
    padding-left: 20px;
    margin-bottom: 4px;
  }
  p {
    margin-bottom: 4px;
  }
  hr {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  blockquote {
    border-left: solid 2px #9bf;
    padding-left: 4px;
  }
}

.form-label-help {
  border: solid 1px #9bf;
  font-size: 10.5px;
  line-height: 1.2em;
  background-color: #e5eeff;
  padding: 4px;
  color: #333;
}
.markdown-panel {
  border: solid 1px #eee;
  padding: 4px 4px 0;
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.qtip-default.qtip-gmcd {
  border-color: #9bf;
  .qtip-titlebar {
    background-color: #cdf;
    font-size: 125%;
  }
  .qtip-content {
    background-color: #e5eeff;
  }
}
