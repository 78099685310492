.content-editable {
  padding: 0.25em 4px 0;
  min-height: 1.5em;
  white-space: normal;
  line-height: 1.2em;
  border: solid 1px #ddbbcf;
}
[contenteditable="true"]:active,
[contenteditable="true"]:focus {
  border: none;
  outline: none;
}
.content-editable:focus {
  color: #900;
  border: solid 1px #d5aac4;
  background-color: #e5eeff;
  box-shadow: 0 0 0 .2rem rgba(176, 85, 136, .25);
  text-decoration: none;
}
